<template>
	<div class="edit-form-container mrow flex wrap order-form">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form mcol-xs-12 mcol-sm-6"
			label-width="150px"
			:model="formData"
			:rules="rules"
		>
			<!-- <el-form-item label="Cookie hash">
				<div class="el-input">{{ formData.cookie_hash }}</div>
			</el-form-item> -->

			<el-form-item
				label="Статус заказа" prop="order_status_id">
				<SimpleSpinner :active="orderStatusesLoading" small />

				<el-select
					v-model="formData.order_status_id"
					placeholder="выберите статус"
					:disabled="!orderStatusesList.length"
				>
					<el-option
						v-for="item in orderStatusesList"
						:key="'status-' + item.id"
						:label="item.title"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>

			<el-form-item
				 label="Пользователь из списка?">
				<el-switch v-model="selectUser" />
			</el-form-item>

			<el-form-item
				 v-if="selectUser" label="Пользователь" prop="user_id">
				<SimpleSpinner :active="usersLoading" small />

				<el-select v-model="formData.user_id" placeholder="выберите пользователя">
					<el-option
						v-for="item in usersList"
						:key="'user_id-' + item.id"
						:label="item.full_name"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>

			<div v-else class="el-form-item">
				<el-form-item label="Имя" prop="user.first_name">
					<el-input v-model="formData.user.first_name" />
				</el-form-item>

				<el-form-item label="Фамилия" prop="user.last_name">
					<el-input v-model="formData.user.last_name" />
				</el-form-item>

				<el-form-item label="Email" prop="user.email">
					<el-input v-model="formData.user.email" type="email" />
				</el-form-item>
			</div>

			<!-- ---------------------------- -->
			<el-form-item label="Способ доставки" prop="delivery_type">
				<el-select v-model="formData.delivery_type" placeholder="выберите способ">
					<el-option
						v-for="item in deliveryTypesList"
						:key="'delivery_type-' + item.id"
						:label="item.title_ru"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>

			<div
				v-if="formData.delivery_type === DELIVERY_TYPES.REGION"
				class="el-form-item"
			>
				<el-form-item label="Регион доставки" prop="delivery_region_id">
					<SimpleSpinner :active="deliveryRegionsLoading" small />
					<el-select
						v-model="formData.delivery_region_id"
						filterable
						:disabled="!deliveryRegionsList.length"
						:placeholder="'выберите регион'"
					>
						<el-option
							v-for="item in deliveryRegionsList"
							:key="'delivery_region_id-' + item.id"
							:label="`${item.title_ru} (${item.price}₪)`"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>
			</div>

			<el-form-item label="Способ оплаты" prop="payment_type">
				<el-select v-model="formData.payment_type" placeholder="выберите способ">
					<el-option
						v-for="item in paymentsTypesList"
						:key="'payment_type-' + item.id"
						:label="item.title_ru"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>

			<div
				v-if="formData.payment_type === PAYMENTS_TYPES.CARD"
				class="el-form-item flex mrow inline-form-row"
			>
				<!-- <label class="label"></label> -->
				<el-form-item prop="credit_card_number" class="mcol-xs-12 mcol-sm-5">
					<el-input v-model="formData.credit_card_number" placeholder="№ карты" />
				</el-form-item>
				<el-form-item prop="credit_card_month" class="mcol-xs-12 mcol-sm-2">
					<el-input v-model="formData.credit_card_month" placeholder="Месяц" />
				</el-form-item>
				<el-form-item prop="credit_card_year" class="mcol-xs-12 mcol-sm-3">
					<el-input v-model="formData.credit_card_year" placeholder="Год" />
				</el-form-item>
				<el-form-item prop="credit_card_cvv" class="mcol-xs-12 mcol-sm-2">
					<el-input
						v-model="formData.credit_card_cvv"
						placeholder="cvv"
						type="password"
						maxlength="3"
						minlength="3"
					/>
				</el-form-item>
			</div>

			<el-form-item label="Телефон" prop="phone_number">
				<el-input v-model="formData.phone_number" type="phone_number" />
			</el-form-item>

			<el-form-item label="Комментарий" prop="comment">
				<el-input v-model="formData.comment" type="textarea" rows="4" />
			</el-form-item>
		</el-form>

		<div class="el-form-item options-container mcol-xs-8">
			<div class="el-form-item__label mcol-xs-12">Товары:</div>

			<div v-if="orderProductsItemsList.length" class="content-row">
				<OrderProductItem
					v-for="(item, idx) in orderProductsItemsList"
					ref="OrderProductItem"
					:key="`OrderProductItem_item-${item.id}`"
					class="drug-n-drop-item"
					required
					:products-list="productsList"
					:products-loading="productsLoading"
					:item-data="item"
					:item-index="idx"
					@onRemove="id => removeFormItem(id, 'orderProductsItemsList')"
					@ready="blockReady"
					@recalc_amount="handleCalcAmount"
				/>
			</div>

			<div class="content-row option-item-container">
				<el-button
					class="action-button create-button"
					size="mini"
					type="success"
					icon="icomoon icon-cross"
					@click="addFormItem('orderProductsItemsList', 'op_i-')"
				/>
			</div>
		</div>

		<div  class="mcol-xs-12 mcol-sm-4 order-info-wrapper">
			<div></div>
			<div class="order-info-block card">
				<div class="info-item flex ">
					<div class="label">Сумма:</div>
					<div class="info"><b v-text="`${total_amount}₪`"></b></div>
				</div>

				<div class="info-item flex">
					<div class="label">Доставка:</div>
					<div class="info"><b v-text="`${delivery_amount}₪`"></b></div>
				</div>

				<div class="info-item total flex">
					<div class="label">Всего:</div>
					<div class="info"><b v-text="`${total_amount + delivery_amount}₪`"></b></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
	DELIVERY_TYPES,
	deliveryTypesList,
	PAYMENTS_TYPES,
	paymentsTypesList,
	PAYMENTS_STATUSES
} from '@/constants/global';
import { required } from '@/constants/validation';
import {
	itemFormMixin,
	itemsFetchSetMixin,
	createFormItemMixin,
	refsOperationsMixin
} from '@/mixins';
import { removeObjProps, getItemValue, updateFormData, findItemBy} from '@/helpers';

export default {
	components: {
		OrderProductItem: () => import('./OrderProductItem.vue')
	},
	mixins: [
		itemFormMixin,
		itemsFetchSetMixin,
		createFormItemMixin,
		refsOperationsMixin
	],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			selectUser: false,
			orderProductsItemsList: [],

			total_amount: 0,
			delivery_amount: 0,

			formData: {
				user_id: null,
				order_status_id: null,
				payment_type: null,
				delivery_type: null,
				delivery_region_id: null,
				phone_number: '',
				comment: '',
				credit_card_number: '',
				credit_card_month: '',
				credit_card_year: '',
				credit_card_cvv: '',
				user: {
					first_name: '',
					last_name: '',
					email: ''
				},
				orderProducts: []
			}
		};
	},

	computed: {
		...mapState({
			orderStatusesList: state => state.order_statuses.itemsList,
			orderStatusesLoading: state => state.order_statuses.isLoading,
			usersList: state => state.users.itemsList,
			usersLoading: state => state.users.isLoading,
			deliveryRegionsList: state => state.delivery_regions.itemsList,
			deliveryRegionsLoading: state => state.delivery_regions.isLoading,
			productsList: state => state.products.itemsList,
			productsLoading: state => state.products.isLoading,

			authUser: state => state.auth.authUser
		}),

		// orderStatusesList: () => orderStatusesList,
		DELIVERY_TYPES: () => DELIVERY_TYPES,
		deliveryTypesList: () => deliveryTypesList,
		PAYMENTS_TYPES: () => PAYMENTS_TYPES,
		paymentsTypesList: () => paymentsTypesList,

		getItemValue: () => getItemValue,

		rules() {
			// delivery_type: required,
			const { selectUser, formData, itemData } = this;

			return {
				payment_type: required,
				order_status_id: required,
				user: !selectUser
					? {
							first_name: required,
							last_name: required,
							email: required
					  }
					: {},
				delivery_region_id:
					formData.payment_type === DELIVERY_TYPES.REGION ? required : null,
				credit_card_number: this.getCardFieldRule(formData, itemData),
				credit_card_month: this.getCardFieldRule(formData, itemData),
				credit_card_year: this.getCardFieldRule(formData, itemData),
				credit_card_cvv: this.getCardFieldRule(formData, itemData),
			};
		},

		initialSetFetchSettings: () => [
			{
				action: 'fetch_order_statuses',
				payload: { params: { max: -1 } },
				clean_up: 'set_order_statuses'
			},
			{
				action: 'fetch_products',
				payload: { params: { max: -1 } },
				clean_up: 'set_products'
			},
			{
				action: 'fetch_delivery_regions',
				payload: { params: { max: -1 } },
				bindTo: { prop: 'formData.delivery_type',/*, fetch_once: true*/ },
				clean_up: 'set_delivery_regions'
			}
		],

		refsList: () => ['OrderProductItem'],
		refsOperationsSettings: () => ({
			submitActionName: 'submitForm',
			dataAsArray: true,
			itemSubmitMethod: 'validateItemForm'
		})
	},

	methods: {
		...mapActions({
			fetch_order_statuses: 'order_statuses/fetch_order_statuses',
			set_order_statuses: 'order_statuses/set_order_statuses',
			fetch_users: 'users/fetch_users',
			set_users: 'users/set_users',
			fetch_products: 'products/fetch_products',
			set_products: 'products/set_products',
			fetch_delivery_regions: 'delivery_regions/fetch_delivery_regions',
			set_delivery_regions: 'delivery_regions/set_delivery_regions'
		}),

		getCardFieldRule(formData, itemData) {
			return formData.payment_type === PAYMENTS_TYPES.CARD &&
						 (
						 	(itemData && itemData.payment_status === PAYMENTS_STATUSES.NO_PAYED) ||
						 	!itemData
						 ) ? 
						 	required : null;
		},

		setupForm(itemData, formData) {
			// console.log(itemData, formData)
			this.orderProductsItemsList = this.setupFormSubItemsList(
				itemData.orderProducts,
				'op_i'
			);

			if (itemData.user_id) this.selectUser = true;

			return updateFormData(itemData, formData);
		},

		localPrepareSubmitData(data) {
			let newData = data;
			const { payment_type, delivery_type } = newData;

			if (this.selectUser) {
				delete newData.user;
			} else {
				delete newData.user_id;
			}

			if (payment_type !== PAYMENTS_TYPES.CARD) {
				newData = removeObjProps(newData, [
					'credit_card_number',
					'credit_card_month',
					'credit_card_year',
					'credit_card_cvv'
				]);
			}

			if (delivery_type !== DELIVERY_TYPES.REGION) {
				newData = removeObjProps(newData, ['delivery_region_id']);
			}

			return newData;
		},

		handleCalcAmount() {
			let total_amount = 0;

			for (const refItem of this.$refs['OrderProductItem']) {
				if (refItem) {
					total_amount += refItem.total_amount;
				}
			}

			this.total_amount = total_amount;
		},

		calcDeliveryAmount(regionId, deliveryRegionsList) {
			// console.log('del', regionId, deliveryRegionsList)
			const region = findItemBy('id', regionId, deliveryRegionsList);
			if (region) return region.price;			
			return 0;
		},

		removeFormItem({ id, listName }) {
			this[listName] = this[listName].filter(o => o.id !== id);

			this.$nextTick().then(() => {
				this.handleCalcAmount();				
			});
		}
	},

	watch: {
		selectUser(value) {
			if (value && !this.usersList.length) {
				this.fetch_users({ params: { max: -1 } });
			}
		},

		'formData.delivery_region_id'(id) {
			if (id && this.deliveryRegionsList.length) {
				this.delivery_amount = this.calcDeliveryAmount(id, this.deliveryRegionsList);
				
			}
		},
		deliveryRegionsList(list) {
			if (this.formData.delivery_region_id && list.length) {
				this.delivery_amount = this.calcDeliveryAmount(this.formData.delivery_region_id, list);
			}
		}
	}
};
</script>
